"use client"

import { useCreateQuote } from "@/modules/Quote/hooks/use-create-quote"
import { getQuote, sendUtm } from "@/shared/http"
import { Breed, BreedWeight, Owner, Pet, Utms } from "@/shared/types/global-store"
import { PET_OLD_DISEASES, PetOldDiseases } from "@/shared/types/lead-api-types"
import { gtag } from "@/shared/utils/gtm"
import { generatePetId, generateUserId, hasUtm } from "@/shared/utils/helpers"
import { trackLeadAttribution } from "@/shared/utils/tracking"
import { useInterpret, useSelector } from "@xstate/react"
import { usePathname, useRouter } from "next/navigation"
import { type PropsWithChildren, createContext, useContext } from "react"
import type { InterpreterFrom } from "xstate"

import { subscriptionFlowMachine } from "./machine"

const FLOW_STATE_KEY = "flow-state-fr"

export function clearState() {
  if (typeof window === "undefined") {
    return
  }

  localStorage.removeItem(FLOW_STATE_KEY)
}

function rehydrateState() {
  if (typeof window === "undefined") {
    return subscriptionFlowMachine.initialState
  }

  return (
    (JSON.parse(
      localStorage.getItem(FLOW_STATE_KEY) || "false"
    ) as typeof subscriptionFlowMachine.initialState) || subscriptionFlowMachine.initialState
  )
}

export const GlobalStateContext = createContext<InterpreterFrom<
  typeof subscriptionFlowMachine
> | null>(null)

export const GlobalStateProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const pathname = usePathname()

  const { mutateAsync } = useCreateQuote()

  const redirect = (path: string) => {
    if (pathname === path) {
      return
    }
    gtag({
      event: "gtm.historyChange",
      page: path,
    })
    // @ts-ignore string is not assignable to type NextRouter, but type NextRouter is not exported
    router.push(path)
  }

  const subscriptionFlowService = useInterpret(
    subscriptionFlowMachine,
    {
      devTools: true,
      actions: {
        goToPetName: () => redirect("/quote/petName"),
        goToPetBirthDate: () => redirect("/quote/petBirthdate"),
        goToPetSex: () => redirect("/quote/petSex"),
        goToPetType: () => redirect("/quote/petType"),
        goToPetBreed: () => redirect("/quote/petBreed"),
        goToTribe: () => redirect("/quote/tribe"),
        goToOwnerAddress: () => redirect("/quote/userAddress"),
        goToOwnerInformations: () => redirect("/quote/userInformations"),
        goToPrepackages: () => redirect("/quote/prepackages"),
        goToCoverage: () => redirect("/quote/healthCoverage"),
        goToParameters: () => redirect("/quote/healthCoverageParam"),
        goToPricing: () => redirect("/quote/pricing"),
        goToTribeQuote: () => redirect("/quote/tribeQuote"),
        goToOwnerBirthDate: () => redirect("/quote/userBirthInformations"),
        goToOwnerMailAndPhone: () => redirect("/quote/userMailPhone"),
        goToAttribitionQuestion: () => redirect("/quote/attributionQuestion"),
        goToRecap: () => redirect("/quote/recap"),
        goToPetOldDiseases: () => redirect("/quote/petOldDiseases"),
        // 👇 this is a hack to start page transition on pet health page
        goToPetHealth: (ctx) => redirect(`/quote/petHealth/${ctx.currentPetId || ""}`),
      },
      state: rehydrateState(),
      services: {
        createQuote: async (ctx) => {
          const quoteIdFromRedirectEmail = localStorage.getItem("quoteId")
          if (quoteIdFromRedirectEmail) {
            try {
              // we get all the quote data from the quoteId but we need to update the machine to have a state that takes the quote data and put it in the context
              const quote = await getQuote(quoteIdFromRedirectEmail)
              if (quote.success === false) {
                return mutateAsync({
                  quoteId: ctx.meta.quote_id,
                })
              }
              // == Pets ==
              ctx.pets = quote.data.map((pet) => {
                // == Breed ==
                const {
                  pet_race: race,
                  pet_father_race: father_race,
                  pet_mother_race: mother_race,
                  pet_weight: weight,
                } = pet

                let breed: Breed = {
                  race: race,
                }

                if (race === "mixed_breed") {
                  if (father_race === "unknown" && mother_race === "unknown") {
                    breed = {
                      race: "mixed_breed",
                      weight: weight as BreedWeight,
                    }
                  } else {
                    breed = {
                      race: "mixed_breed",
                      father_race,
                      mother_race,
                    }
                  }
                }

                // == Health ==
                const lowerCaseDiseases = PET_OLD_DISEASES.map((disease) => disease.toLowerCase())
                const petDiseases = pet.pet_old_diseases?.filter((disease) =>
                  lowerCaseDiseases.includes(disease.toLocaleLowerCase() as PetOldDiseases)
                )
                const petOtherDiseases = pet.pet_old_diseases?.filter(
                  (disease) =>
                    !lowerCaseDiseases.includes(disease.toLocaleLowerCase() as PetOldDiseases)
                )

                if (petOtherDiseases?.length > 0) {
                  petDiseases.push("Autre")
                }

                type PriceInEuros = number
                const preventionPrices: { [preventionLimitInEuros: number]: PriceInEuros } = {
                  100: 7,
                  150: 11,
                  200: 14,
                }

                // == Pet ==
                return {
                  name: pet.pet_name,
                  type: pet.pet_type as Pet["type"],
                  sex: pet.pet_sexe as Pet["sex"],
                  idx: pet.pet_idx,
                  id: generatePetId(),
                  birthday: pet.pet_birthday,
                  uuid: pet.pet_uuid || "11223344",
                  uuid_type: (pet.pet_uuid_type as Pet["uuid_type"]) || "chip",
                  breed,
                  health: {
                    status: "healthy",
                    diseases: petDiseases,
                    other_diseases: petOtherDiseases,
                  },
                  selectedFormula:
                    pet.options && pet.coverage
                      ? {
                          rate: pet.options.rate,
                          health_limit: pet.coverage.health_limit,
                          price: pet.original_price
                            ? Number(pet.original_price)
                            : Number(pet.price),
                          prevention: Boolean(pet.options.prevention === "yes"),
                          death: false,
                          prevention_limit: Number(pet.coverage.prevention_limit),
                          prevention_price: preventionPrices[Number(pet.coverage.prevention_limit)],
                        }
                      : undefined,
                  isPetDraft: false,
                  isQuoteCustomized: Boolean(pet.coverage) && Boolean(pet.options),
                  subscription_start_at: pet.subscription_start_at,
                }
              }) as Pet[]

              ctx.owner = {
                firstname: quote.data[0]?.owner_firstname || quote.pet_owner.owner_firstname,
                lastname: quote.data[0]?.owner_lastname || quote.pet_owner.owner_lastname,
                email: quote.data[0]?.owner_email || quote.pet_owner.owner_email,
                phone: quote.data[0]?.owner_phone || quote.pet_owner.owner_phone,
                birthday: quote.data[0]?.owner_birthday || quote.pet_owner.owner_birthday,
                birthcity: quote.data[0]?.owner_birthcity || quote.pet_owner.owner_birthcity,
                address: quote.data[0]?.address || quote.pet_owner.address,
              } as Owner

              // == Meta ==
              ctx.meta.subscription_start_at = "asap"
              ctx.meta.quote_id = quoteIdFromRedirectEmail
              ctx.meta.user_id = quote.data[0]?.user_id || generateUserId()
              ctx.meta.from_lp = true
              ctx.meta.ab_tests = quote.data[0]?.ab_tests || { prepackages: { variant: "A" } }
              ctx.meta.start_date = quote.data[0]?.start_date ?? ""

              if (ctx.pets && ctx.pets.length === 1) {
                ctx.currentPetId = ctx.pets[0]?.id
              }
              if (quote.data[0]?.raf_code) {
                ctx.meta.raf_code = quote.data[0].raf_code
              }

              // == Marketing ==
              ctx.marketing = quote.data[0]?.marketing || {}

              return { data: { quote_id: quoteIdFromRedirectEmail } }
            } catch {
              return mutateAsync({
                quoteId: ctx.meta.quote_id,
              })
            }
          }

          return mutateAsync({
            quoteId: ctx.meta.quote_id,
          })
        },
        urlParamsHandler: (ctx) => {
          const utmsFromLocalStorage = localStorage.getItem("utmParams")
          const utmParams = utmsFromLocalStorage
            ? (JSON.parse(utmsFromLocalStorage) as Utms)
            : {
                utm_source: "",
                utm_medium: "",
                utm_campaign: "",
                utm_content: "",
                utm_term: "",
                utm_comparateur: "",
                gclid: "",
                compclid: "",
                at_gd: "",
                advertiser_id: "",
                channel_id: "",
                market: "",
                click_id: "",
                click_ref: "",
                s_id: "",
                affiliate_gclid: "",
              }
          const referrer = localStorage.getItem("referrer") || ""
          if (hasUtm(utmParams) || referrer) {
            if (Boolean(utmParams.compclid)) {
              trackLeadAttribution(utmParams.compclid)
            }
            return sendUtm(ctx.meta.quote_id, utmParams, referrer)
          }
          return Promise.resolve()
        },
      },
    },
    (state) => {
      localStorage.setItem(FLOW_STATE_KEY, JSON.stringify(state))
    }
  )

  return (
    <GlobalStateContext.Provider value={subscriptionFlowService}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => {
  const globalState = useContext(GlobalStateContext)

  if (!globalState) {
    throw new Error("useGlobalState has to be used within <GlobalStateProvider.Provider>")
  }

  const context = useSelector(globalState, (state) => state.context)
  const send = globalState.send

  const validPets = useSelector(globalState, (state) =>
    state.context.pets.filter((pet) => !pet.isPetDraft)
  )
  const hasMultiplePets = validPets.length > 1

  function getCurrentPet() {
    if (!context.currentPetId || context.pets.length === 0) {
      return null
    }

    return context.pets.find((pet) => pet.id === context.currentPetId)
  }

  const currentPet = getCurrentPet()

  return {
    context,
    send,
    globalState,
    currentPet,
    helpers: {
      getCurrentPet,
      hasMultiplePets,
      validPets,
    },
  }
}
