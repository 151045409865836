import { createQuote, addPet } from "@/shared/http"
import { Pet } from "@/shared/types/global-store"
import { SyncBackPet } from "@/shared/types/lead-api-types"
import { useMutation } from "@tanstack/react-query"

function adaptPetToBackend(pet?: Pet) {
  if (!pet) {
    return undefined
  }
  return {
    idx: pet.idx,
    name: pet.name,
    birthday: pet.birthday,
    meta: {
      prevention_version: "v2",
      subscription_start_at: "asap",
    },
    uuid: pet.uuid || "11223344",
    uuid_type: pet.uuid_type,
    sexe: pet.sex,
    type: pet.type,
    breed: pet.breed || { race: "mixed_breed" },
    pricing: undefined,
  }
}

export const useCreateQuote = () => {
  return useMutation({
    mutationKey: ["create-quote"],
    mutationFn: async ({ quoteId, petIdx }: { quoteId?: string; petIdx?: number }) => {
      const quote = await createQuote(quoteId, petIdx)

      return quote
    },
    retry: 0,
  })
}

export const useAddPet = () => {
  return useMutation({
    mutationKey: ["add-pet"],
    mutationFn: async ({ quoteId, pet }: { quoteId?: string; pet?: Pet }) => {
      const response = await addPet(quoteId, adaptPetToBackend(pet) as SyncBackPet)

      return response
    },
    retry: 0,
  })
}
