import { syncBack } from "@/shared/http"
import { PetHealthNotHealthy } from "@/shared/types/global-store"
import { SyncBackBody } from "@/shared/types/lead-api-types"
import { SubscriptionFlowContext } from "@/store/machine"
import { useMutation } from "@tanstack/react-query"

export function buildSyncBackBody(ctx: SubscriptionFlowContext): SyncBackBody {
  return {
    product: "dalma",
    meta: {
      quote_id: ctx.meta.quote_id,
      referrer: "referer",
      user_id: ctx.meta.user_id,
      ...(ctx.meta?.raf_code && { raf_code: ctx.meta.raf_code }),
      ...(ctx.meta?.promo_code && { promo_code: ctx.meta.promo_code }),
      from_lp: ctx.meta?.from_lp ?? false,
      ab_tests: ctx.meta.ab_tests,
      start_date: ctx.meta.start_date,
      subscription_mode: ctx.meta.subscription_mode || "ASAP",
    },
    marketing: {
      referrer_details: ctx.marketing.referrer_details,
      referrer_type: ctx.marketing.referrer_type,
    },
    owner: {
      birthday: ctx.owner.birthday || null,
      firstname: ctx.owner.firstname || null,
      lastname: ctx.owner.lastname || null,
      address: ctx.owner.address || null,
      birthcity: ctx.owner.birthcity || null,
      phone: ctx.owner.phone || null,
      email: ctx.owner.email || null,
    },
    pets: ctx.pets.map((pet) => {
      return {
        idx: pet.idx,
        name: pet.name,
        birthday: pet.birthday,
        meta: {
          prevention_version: "v2",
          subscription_start_at: "asap",
        },
        uuid: pet.uuid || "11223344",
        uuid_type: pet.uuid_type,
        sexe: pet.sex,
        type: pet.type,
        breed: pet.breed || { race: "mixed_breed" },

        ...(pet?.health?.status && {
          health:
            pet.health?.status === "healthy"
              ? {
                  status: "healthy",
                }
              : ({
                  status: "not_healthy",
                  old_diseases: pet.health.other_diseases
                    ? [...pet.health.old_diseases, pet.health.other_diseases]
                    : pet.health.old_diseases,
                } as PetHealthNotHealthy),
        }),

        pricing: pet.selectedFormula
          ? {
              accepted_formula: "formula_dalma",
              options: {
                pack: "accident_sick",
                rate: pet.selectedFormula.rate,
                liability: "",
                prevention: pet.selectedFormula.prevention,
                death: false,
              },
              coverage: {
                health_limit: pet.selectedFormula.health_limit,
                prevention_limit: pet.selectedFormula.prevention_limit?.toString(),
              },
            }
          : undefined,
      }
    }),
  }
}

export const useSyncBack = () => {
  return useMutation({
    mutationKey: ["sync-back"],
    mutationFn: async (ctx: SubscriptionFlowContext) => {
      const body = buildSyncBackBody(ctx)
      const res = await syncBack(body)
      
      return res.success
    },
  })
}
