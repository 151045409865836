import { Breed, Meta, Owner, PetHealth } from "./global-store"

export type CreateQuoteResponse = {
  accept_quote: boolean
  pet_idx: 0
  quote_id: string
  start_date: string
}[]

export type NewQuoteResponse = {
  data: {
    accept_quote: boolean
    pet_idx: 0
    quote_id: string
    start_date: string
  }
}

export type SyncBackPet = {
  name: string
  idx: number
  type: "dog" | "cat"
  sexe: "MALE" | "FEMALE"
  /**
   * format: "YYYY-MM-DD"
   */
  birthday: string

  meta: {
    subscription_start_at: "asap" | "later" // will be "asap" for now
    prevention_version: "v2" // will be "v2" for now
  }

  uuid: string
  uuid_type: "tatoo" | "chip"

  health: PetHealth

  breed:
    | {
        race?: string
      }
    | {
        race?: "mixed_breed"
        father_race: string
        mother_race: string
      }
    | {
        race?: "mixed_breed"
        father_race: "unknown"
        weight: string
        mother_race: "unknown"
      }

  pricing?: {
    accepted_formula: "formula_dalma"
    options: {
      pack: "accident_sick"
      rate: string
      liability: ""
      prevention: boolean
      death: boolean
    }
    coverage: {
      health_limit: string
      prevention_limit?: string
    }
  }
}

export type SyncBackBody = {
  meta: {
    quote_id: string
    user_id: string
    referrer: string // can be empty string
    raf_code?: string
    promo_code?: string
    from_lp?: boolean
    start_date?: Date | string
    subscription_mode?: "ASAP" | "DEFERRED"
    ab_tests?: {
      prepackages: {
        variant: "A" | "B" | "default"
      }
    }
  }
  marketing: {}
  owner: Owner
  pets: SyncBackPet[]
  product: "dalma"
}

export type PreventionOption = {
  prevention_limit: number
  prevention_price: number
}

export type PreventionOptions = Record<number, PreventionOption>

export type Price = {
  accident_sick: number
  accident: number
}
type WellnessPriceInEurosPerMonth = number
export type PetPricings = {
  prevention: { [wellnessLimitInEurosPerYear: string]: WellnessPriceInEurosPerMonth }
  prices: {
    [rate_coverage: string]: Price
  }
  recommended: string
}

export type PricingResponse = {
  [petIdx: string]: PetPricings
}

type ValidateQuotePricing = {
  accepted_formula: "formula_dalma_de"
  coverage: {
    surgery_limit: string
    health_limit: string
    prevention_limit: string
  }
  options: {
    pack: "surgery" | "health"
    surgery_rate: string
    health_rate: string
    prevention: boolean
  }
}

export type ValidateQuotePets = {
  idx: number
  name: string
  sexe: "MALE" | "FEMALE" // Typo error in the API
  type: "cat" | "dog"
  breed: Breed
  /** format: `yyyy-MM-dd` */
  birthday: string
  pricing: ValidateQuotePricing
  uuid: string
  uuid_type: "chip" | "tatoo"
  health: PetHealth
}[]

export type ValidateQuoteData = {
  meta: Meta
  owner: Owner
  pets: ValidateQuotePets
  product: "dalma"
}

export const PET_OLD_DISEASES = [
  "Allergies",
  "Cancer",
  "Arthrose",
  "Chlamydia",
  "Coryza",
  "Cystite",
  "Insuffisance rénale",
  "Maladie de carré",
  "Maladie de Lyme",
  "Parvovirose",
  "Piroplasmose",
  "Rage",
  "Tétanos",
  "Torsion de l'estomac",
  "Toxoplasmose",
  "Autre",
] as const

export type PetOldDiseases = (typeof PET_OLD_DISEASES)[number]
